import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Le blog qui fait Plaiz - Plaiz, le réseau social de la mode"/>
    <h1 className={"p1"}>Coming Soon</h1>
    {/*<Link to="/" style={styles.link}><h4
      style={styles.backToHomeLink}
    ><img src={rond_color}/>Revenir à la page d'accueil</h4></Link>*/}
    <h4
      style={styles.backToHomeLink}
    ><Link to="/">Revenir à la page d'accueil</Link></h4>
  </Layout>
)

const styles = {
  backToHomeLink: {
    color: "white",
  },
}

export default SecondPage
